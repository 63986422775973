
import { computed, defineComponent, ref, toRef } from 'vue'
import Modal from '@/components/molecules/Modal.vue'
import Details from '@/components/atom/Details.vue'
import InputField from '@/components/atom/InputField.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import MainApi from '@/api/MainApi'
import { useRoute } from 'vue-router'
import type { searchType, ReferenceFields } from '@/types/search'
import useNumFormatter from '@/utils/filter'
import useGetMedia from '@/utils/getMedia'
import _ from 'lodash'
export default defineComponent({
  props: {
    items: {
      type: Array as () => {
        _id: string
        _source: searchType
      }[],
      default: () => []
    },
    heading: {
      type: Array as () => { id: number; type: string }[],
      default: () => []
    },
    withoutFile: {
      type: Boolean,
      default: false
    },
    sortOptions: {
      type: Object as () => { columnId: number; order: string },
      required: true
    }
  },
  components: {
    Modal,
    Details,
    InputField,
    CustomButton
  },
  setup(props, { emit }) {
    const { numFormatter } = useNumFormatter()
    const { getMedia } = useGetMedia()
    const route = useRoute()
    const dossierResult = ref<{ reference: string; comment: string }>({
      reference: '',
      comment: ''
    })
    const formSubmitted = ref(false)
    const successText = ref('')
    const alertText = ref('')
    const modalType = ref('')
    const searchDetails: any = ref({})
    const sort = toRef(props, 'sortOptions')
    const openModal = async (type: string, value: searchType) => {
      searchDetails.value = value
      if (type === 'remove') {
        alertText.value = `U gaat item verwijderen uit dossier ${route.params.id}. Weet u zeker dat u het item wilt verwijderen?`
      }
      modalType.value = type
    }
    const closeModal = (option: string) => {
      if (option === 'updatePage') {
        emit('updatePage')
      }
      modalType.value = ''
      formSubmitted.value = false
      searchDetails.value = Object.assign({})
      clearFields()
    }
    const addDossier = async () => {
      formSubmitted.value = true
      if(taxatieError.value) {
        return
      }
      const itemID: string = searchDetails.value.id
      const result = await MainApi.updateReference({ projectcode: route.params.id.toString() }, itemID)
      const objectID = await MainApi.getItems(route.params.id.toString())
      let itemCode: string[] = []
      let creatDossier
      let updateDossier
      if (objectID.found === false) {
        creatDossier = await MainApi.creatDossier(route.params.id.toString(), { object_id: [itemID] })
      } else {
        itemCode = objectID.result._source.object_id.concat(itemID)
        updateDossier = await MainApi.updateDossier(route.params.id.toString(), { object_id: itemCode })
      }
      const updateComment = await MainApi.updateComment(dossierResult.value, itemID)
      if (result && updateComment && (updateDossier || creatDossier)) {
        modalType.value = 'success'
        successText.value = `Item is toegevoegd aan dossier ${route.params.id}`
        clearFields()
      }
    }

    const clearFields = () => {
      dossierResult.value.reference = ''
      dossierResult.value.comment = ''
    }

    const removeResult = async () => {
      const itemID: string = searchDetails.value.id
      const result = await MainApi.removeDossier({ projectcode: route.params.id.toString() }, itemID)
      const objectID = await MainApi.getItems(route.params.id.toString())
      const itemCode: string[] = objectID.result._source.object_id
      const itemIndex = itemCode.indexOf(searchDetails.value.id)
      if (itemIndex > -1) {
        itemCode.splice(itemIndex, 1)
      }
      const updateDossier = await MainApi.updateDossier(route.params.id.toString(), { object_id: itemCode })
      if (result && objectID && updateDossier) {
        modalType.value = 'removed-sucess'
        successText.value = `Item ${searchDetails.value.id} is verwijderd uit dossier ${route.params.id}`
      }
    }
    const isExist = (projectCode: Array<string>) => {
      if (projectCode && projectCode.length > 0) {
        const itemExist = projectCode.filter((item: string) => parseInt(item) === parseInt(route.params.id.toString()))
        if (itemExist && itemExist.length > 0) {
          return false
        }
        return true
      }
      return true
    }

    const sortRecords = _.debounce((columnId: number) => {
      if(sort.value.columnId !== columnId){
        sort.value.columnId = columnId
        sort.value.order = ''
      }
      switch (sort.value.order) {
        case '':
          sort.value.order = 'asc'
          break
        case 'asc':
          sort.value.order = 'desc'
          break
        case 'desc':
          sort.value.order = ''
          break
      }
      emit('sort', sort.value.columnId, sort.value.order)
    }, 300)

    const getSortIcon = (column: number) => {
      if (sort.value.columnId === column && sort.value.order) {
        return require(`@/assets/icons/sort-${sort.value.order}.svg`)
      } else {
        return require('@/assets/icons/sort.svg')
      }
    }

    // Haven't used vee-validate bcz of single field validation
    const taxatieError =  computed(() => {
      if(formSubmitted.value && !dossierResult.value.reference){
        return 'This field is mandatory'
      } else if(!/^[0-9]*$/.test(dossierResult.value.reference)) {
        return 'Only numbers are allowed'
      }
      return ''
    })

    const referenceError =  computed(() => {
      if(!referenceData.value.reference){
        return 'This field is mandatory'
      } else if(!/^[0-9]*$/.test(referenceData.value.reference)) {
        return 'Only numbers are allowed'
      }
      return ''
    })
    const referenceData = ref<ReferenceFields>({
      id: '',
      reference: '',
      comment: ''
    })

    const editReference = (meta: ReferenceFields) => {
      const {id, reference, comment} = meta
      referenceData.value = {
        id,
        reference,
        comment
      }
    }

    const updateReference = async () => {
      if(!referenceError.value) {
        await MainApi.updateComment({reference: referenceData.value.reference, comment: referenceData.value.comment}, referenceData.value.id)
        editReference({id: '', reference: '', comment: ''})
        emit('updatePage')
      }
    }
    return {
      numFormatter,
      getMedia,
      openModal,
      modalType,
      closeModal,
      searchDetails,
      dossierResult,
      successText,
      alertText,
      addDossier,
      removeResult,
      isExist,
      clearFields,
      sortRecords,
      sort,
      taxatieError,
      referenceData,
      editReference,
      updateReference,
      referenceError,
      getSortIcon
    }
  }
})
